<template>
  <!--    banner-->
  <div class="appInner">
    <div class="carousel">
      <el-carousel>
        <el-carousel-item v-for="(image,index) in imageList" :key="index" :interval="4000">
          <el-image :src="image.src" class="banner" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    imageList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  /*.carousel{*/
  /*  display: block;*/
  /*}*/
  .banner {
    /*width: 1920px;*/
    /*height: 440px;*/
    /*left: 50%;*/
  }
  .appInner{
    /*width: 1920px;*/
    /*display: flex;*/
    align-items: center;
    justify-content: space-between;
  }
</style>

<style>
  .el-carousel__container{
    /*height: 440px;*/
  }
</style>
