<template>
  <div class="scrollbarWrapper">
    <el-scrollbar>
      <el-menu
        :show-timeout="200"
        :default-active="$route.path"
        :default-openeds="openKeys"
        mode="vertical"
        background-color="#efefef"
        text-color="#333"
        active-text-color="#1890ff"
      >
        <sidebar-item v-for="route in profileRouter" :key="route.path" :item="route" :base-path="route.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from './SidebarItem'
import { profileRouter } from '@/router/routers'
export default {
  components: { SidebarItem },
  data() {
    return {
      profileRouter,
      openKeys: ["/profile/detection"]
    }
  }
}
</script>
<style lang="scss" scoped>
  .scrollbarWrapper{
    width:200px;
    margin-right:20px;
  }
</style>
