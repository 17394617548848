<template>
  <div class="menu-wrapper">
    <el-menu-item :index="resolvePath(item)" v-if="!item.hidden && !item.menu && item.hasHiddenChild">
      <app-link :to="resolvePath(item)" :key="item.name" :title="item.name"/>
    </el-menu-item>
    <el-submenu v-if="item.menu && !item.hidden && item.children" ref="submenu" :index="resolvePath(item)">
      <template slot="title">
        <item :title="item.name" />
      </template>
      <template v-for="child in item.children">
        <sidebar-item
          v-if="child.children&&!child.hidden"
          :is-nest="true"
          :item="child"
          :key="child.path"
          :base-path="resolvePath(child)"
          class="nest-menu" />
        <el-menu-item v-else-if="!child.hidden" :index="resolvePath(child)">
          <app-link :to="resolvePath(child)" :key="child.name" :title="child.name"/>
        </el-menu-item>
      </template>
    </el-submenu>
    <template v-for="child in item.children" v-else>
      <el-menu-item :index="resolvePath(child)" v-if="!child.hidden">
        <app-link :to="resolvePath(child)" :key="child.name" :title="child.name"/>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils'
import Item from './Item'
import AppLink from './Link'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onlyOneChild: null
    }
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(item) {
      if (this.isExternalLink(item.routePath)) {
        return item.routePath
      }
      if (!item.menu && item.children && item.hasHiddenChild) {
        return Array.from(new Set(path.resolve(this.basePath, item.path).split('/'))).join(('/'))
      }
      return path.resolve(this.basePath, item.path)
    },
    isExternalLink(routePath) {
      return isExternal(routePath)
    }
  }
}
</script>
