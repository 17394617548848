import router from './routers'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import Config from '@/config'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // getToken from cookie
NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/home', '/about', '/technical', '/goods', '/goods/list', '/culture', '/profile/help/questionAndAnswer', '/evaluation/person', '/evaluation/company'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/home'
      })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (!store.getters.user.id) {
        store.dispatch('GetInfo').then(res => { // 拉取user_info
          next()
          // eslint-disable-next-line handle-callback-err
        }).catch((err) => {
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      } else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
