
<template>
  <a :href="to" :target="isExternalLink(to)?'_blank':'_self'" style="display: block">{{ title }}</a>
<!--  <component v-bind="linkProps(to)">-->
<!--    <slot/>-->
<!--  </component>-->
</template>

<script>
import { isExternal } from '@/utils'

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath)
    },
    linkProps(url) {
      if (this.isExternalLink(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        is: 'router-link',
        to: url
      }
    }
  }
}
</script>
