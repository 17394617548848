<template>
  <div class="appFooter">
    <div class="appInner">
      <!--      团队保障暂时不显示-->
      <!--      <div class="footer-point">-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <img src="../../../assets/images/footer/web.png" alt="">-->
      <!--            <div>-->
      <!--              <h3>官方直营</h3>-->
      <!--              <p>一百余家直营体检中心线上预约、专注健康体检15年</p>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <img src="../../../assets/images/footer/team.png" alt="">-->
      <!--            <div>-->
      <!--              <h3>团队优势</h3>-->
      <!--              <p>拥有成熟的技术开发和专业的团队客服坐席为您服务</p>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <img src="../../../assets/images/footer/tech.png" alt="">-->
      <!--            <div>-->
      <!--              <h3>专业保证</h3>-->
      <!--              <p>拥有三甲医院返聘医师、国际先进设备、高质量的服务</p>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <img src="../../../assets/images/footer/sale.png" alt="">-->
      <!--            <div>-->
      <!--              <h3>售后服务</h3>-->
      <!--              <p>未消费订单及时退、过期退、体检保障和隐私保障</p>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <div class="top">
        <div class="left">
          <img class="logo" src="../../../assets/images/common/logo03.png" alt="">
          <p>疾病潜伏期可读</p>
          <p>疾病潜伏期干预、控制重大疾病发生</p>
          <p>窗口期内的逆转</p>
        </div>
        <div class="right">
          <div class="introItem">
            <h3 class="itemTitle">关于墨尔法勒</h3>
            <ul>
              <li>
                <router-link to="/about">公司介绍</router-link>
              </li>
              <li>
                <router-link to="/about">研究院介绍</router-link>
              </li>
              <li>
                <router-link to="/culture">企业文化</router-link>
              </li>
            </ul>
          </div>
          <div class="introItem">
            <h3 class="itemTitle">客户服务</h3>
            <ul>
              <li>
                <router-link to="/help/questionAndAnswer">帮助中心</router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link to="/">体验卡说明</router-link>-->
              <!--              </li>-->
              <li>
                <router-link to="/technical">隐私声明</router-link>
              </li>
            </ul>
          </div>
          <div class="introItem">
            <h3 class="itemTitle">业务合作</h3>
            <ul>
              <li>
                morphology@yeah.net
                <!--                <router-link to="/">morphology@yeah.net</router-link>-->
              </li>
              <!--              <li>-->
              <!--                <router-link to="/">13811987170</router-link>-->
              <!--              </li>-->
              <!--              <li>-->
              <!--                <router-link to="/">隐私声明</router-link>-->
              <!--              </li>-->
            </ul>
          </div>
          <div class="introItem">
            <h3 class="itemTitle">微信公众号</h3>
            <img
              style="width: 80px;height: 80px;padding-top: 10px"
              src="../../../assets/images/home/morphology-qcode.png"
              alt="">
          </div>
          <!--  小程序没有暂时不放  -->
          <!--          <div class="introItem">-->
          <!--            <h3 class="itemTitle">微信小程序</h3>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="bottom">
        <p><a href="https://mmri.com.cn">© 2016-2020 墨尔法勒 mmri.com.cn 版权所有</a></p>
        <p>备案/许可证编号：<a href="http://beian.miit.gov.cn">京ICP备20007198号</a> | 网站备案：<a href="http://beian.miit.gov.cn">墨尔法勒门户网站 | 京ICP备20007198号-1</a></p>
        <p>主办单位： <a href="https://mmri.com.cn">北京墨尔法勒医学研究院（有限合伙）91110105MA008YYJ52</a> | <a href="https://mmri.com.cn">墨尔法勒生物科技(北京)有限公司 91110114MA01Q7DM1H</a> | 医信堂 91110115MA01RURM95</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WebsiteFooter'
}
</script>

<style lang="scss" scoped>
  .appFooter {
    background: #dedede;
  }

  .footer-point{
    border-bottom: 1px solid #aaa;
    padding: 30px 0;
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        display: flex;
        align-items: center;
        flex:.2;
        font-size: 12px;
        img{
          width:40px;
          margin-right:20px;
        }
        h3{
          padding-bottom: 10px;
        }
        p{
          line-height: 1.5;
        }
      }
    }
  }
  .top {
    border-bottom: 1px solid #aaa;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 300px;
      word-break: break-all;

      img {
        /*background-color: #36a1ad;*/
        height: 60px;
      }

      p {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
      }
    }

    .right {
      display: flex;

      .introItem {
        margin-right: 30px;

        .itemTitle {
          font-size: 14px;
        }

        ul {
          li {
            margin-top: 20px;
            font-size: 12px;
          }
        }
      }

      .introItem:last-child {
        margin-right: 0;
      }
    }
  }

  .bottom {
    text-align: center;
    padding: 20px 0;

    p {
      color: #999;
      font-size: 12px;
      margin: 5px 0;
    }
  }
</style>

