<template>
  <div class="appContainer">
    <website-header/>
    <ad-brand/>
    <div class="appInner">
      <div class="profileContent">
        <side-bar/>
        <div class="profileContentInner">
          <breadcrumb class="breadcrumb-container"/>
          <app-main />
        </div>
      </div>
    </div>
    <website-footer/>
  </div>
</template>
<script>
import { AppMain, WebsiteHeader, WebsiteFooter, AdBrand } from './components'
import SideBar from './components/profile/Sidebar'
import Breadcrumb from '@/components/Breadcrumb'
export default {
  name: 'Layout',
  components: {
    AppMain, WebsiteFooter, WebsiteHeader, SideBar, AdBrand, Breadcrumb
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";
  .appContainer{
    min-width:1200px;
  }
  .profileContent{
    display: flex;
    /*border-top:1px solid #ccc;*/
    padding:20px 0;
    .profileContentInner{
      /*padding-right:20px;*/
      flex: 1;
    }
  }
</style>

