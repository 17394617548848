<template>
  <div class="appHeader">
    <div class="appInner">
      <ul class="left">
        <li class="item"><router-link to="/">
          <!--          <img src="../../../assets/images/header/unnamed.png" style="width: 20px;height: 20px;padding-bottom: 0" alt="">-->
          <span style="text-align: center">墨尔法勒</span>
        </router-link></li>
        <!--        <li class="item"><router-link to="/">研究院</router-link></li>-->
      </ul>
      <ul class="right">
        <li class="item"><router-link to="/technical">公告</router-link></li>
        <li class="item"><router-link to="/account/message">我的消息</router-link></li>
        <li v-if="!user.id" class="item" style="font-weight: bold"><router-link to="/login">登录/注册</router-link></li>
        <li v-if="user.id" class="item">
          <el-dropdown trigger="hover">
            <div class="user"><span>{{ user.nickName }}</span><i class="el-icon-arrow-down"/></div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/account/information">
                <el-dropdown-item>
                  我的资料
                </el-dropdown-item>
              </router-link>
              <router-link to="/account/security">
                <el-dropdown-item>
                  修改密码
                </el-dropdown-item>
              </router-link>
              <el-dropdown-item>
                <div style="padding:0 20px" @click="logout">退出</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li class="item"><router-link to="/profile/home">个人中心</router-link></li>
        <li class="item"><router-link to="/profile/order">我的订单</router-link></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WebsiteHeader',
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .appHeader{
    background: whitesmoke;
    border-bottom:1px solid whitesmoke;
    height:40px;
    color:#333;
    .appInner{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .user{
      color:#333;
    }
    .left,.right{
      display: flex;
      .item{
        height:40px;
        margin-right:20px;
        display: flex;
        align-items: center;
      }
      .user{
        cursor: pointer;
        span{
          margin-right:10px;
        }
      }
      .item:hover,.user:hover{
        color:#409EFF;
      }
      .item:last-child{
        margin-right:0;
      }
    }
  }
</style>

