<template>
  <div class="brand">
    <div class="appInner">
      <a href="/">
        <img class="logo" src="../../../assets/images/common/logo03.png" alt="">
      </a>
      <div class="searchContainer">
        <el-form ref="loginForm" :model="params" class="searchForm">
          <el-form-item>
            <el-input v-model="params.keyword" type="text" auto-complete="off" placeholder="体检" style="width:400px">
              <el-button slot="append" class="searchBtn" type="primary" @click.native.prevent="search">搜索</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <ul class="hotWord">
          <li class="item">老年常见病</li>
          <li class="item">职业病</li>
          <li class="item">心脑血管</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchBrand',
  data() {
    return {
      params: {
        keyword: ''
      }
    }
  },
  methods: {
    search() {

    }
  }
}
</script>

<style lang="scss" scoped>
  .brand{
    background: #fff;
    padding: 20px 0;
  }
  .searchContainer{
    .searchForm{
      .el-form-item{
        margin-bottom:0;
      }
      .searchBtn{
        background: #409EFF;
        color:#fff;
        border:1px solid #409EFF;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .hotWord{
      margin-top:10px;
      display: flex;
      align-items: center;
      li{
        color: #555151;
        margin-right:10px;
        padding: 0 8px;
        height: 22px;
        line-height: 22px;
        margin-right: 10px;
        font-size: 12px;
        background: #f5f5f5;
        cursor: pointer;
      }
    }
  }

  .appInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      width:150px;
    }
  }
</style>

