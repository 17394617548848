import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import WebSiteLayout from '../layout/WebSiteLayout'
import GoodsLayout from '../layout/GoodsLayout'
import ProfileLayout from '../layout/ProfileLayout'

Vue.use(Router)
export const profileRouter = [
  {
    'name': '个人中心',
    'path': '/profile',
    'component': ProfileLayout,
    'menu': true,
    'redirect': 'home',
    'children': [
      {
        'name': '我的个人中心',
        'path': 'home',
        'component': () => import('../views/profile/home')
      },
      {
        'name': '我的订单',
        'path': 'order',
        'component': () => import('../views/profile/order/list')
      },
      {
        'name': '我的预约',
        'path': 'appointment',
        'component': () => import('../views/profile/appointment/list')
      },
      {
        'name': '我的报告',
        'path': 'report',
        'component': () => import('../views/profile/report/list')
      },
      {
        path: 'report-detail',
        component: () => import('../views/profile/report/detail'),
        name: '报告详情',
        hidden: true
      }
    ]
  },
  {
    'name': '我的检测数据',
    'path': '/detection',
    'component': ProfileLayout,
    menu: true,
    redirect: 'record',
    'children': [
      {
        'name': '检测记录',
        'path': 'record',
        'component': () => import('../views/profile/detection/record'),
        menu: false,
        hasHiddenChild: true,
        children: [
          {
            'name': '记录详情',
            'path': 'detail',
            'component': () => import('../views/profile/detection/detail'),
            hidden: true
          }
        ]
      }, {
        'name': '检测曲线',
        'path': 'curve',
        'component': () => import('../views/profile/detection/curve')
      }, {
        'name': '短板示意',
        'path': 'shortBoard',
        'component': () => import('../views/profile/detection/shortBoard')
      }, {
        'name': '检测图片',
        'path': 'record-picture',
        'component': () => import('../views/profile/detection/picture')
        // hidden: true
      }, {
        'name': '病因排序',
        'path': 'record-serial',
        'component': () => import('../views/profile/detection/serial')
        // hidden: true
      }, {
        'name': '趋势指向',
        'path': 'record-detail',
        'component': () => import('../views/profile/detection/detail')
        // hidden: true
      }, {
        'name': '生理构筑',
        'path': 'bio-radar',
        'component': () => import('../views/profile/detection/bioRadar')
        // hidden: true
      }
    ]
  },
  {
    'name': '我的账号',
    'path': '/account',
    'component': ProfileLayout,
    'menu': true,
    'children': [
      {
        'name': '个人资料',
        'path': 'information',
        'component': () => import('../views/profile/account/information')
      },
      {
        'name': '账号安全',
        'path': 'security',
        'component': () => import('../views/profile/account/security')
      },
      {
        'name': '我的消息',
        'path': 'message',
        'component': () => import('../views/profile/account/message')
      }
    ]
  },
  {
    'name': '帮助中心',
    'path': '/help',
    'component': ProfileLayout,
    redirect: '/profile/help/questionAndAnswer',
    'menu': true,
    'children': [
      {
        'name': '常见问题',
        'path': 'questionAndAnswer',
        'component': () => import('../views/profile/help/questionAndAnswer')
      }
    ]
  }
]

export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    component: WebSiteLayout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/website/home'),
        name: '首页',
        meta: {
          title: '首页',
          noCache: true,
          affix: true
        }
      },
      {
        path: 'about',
        component: () => import('@/views/website/about'),
        name: '关于我们',
        meta: {
          title: '关于我们',
          noCache: true,
          affix: true
        }
      },
      {
        path: 'technical',
        component: () => import('@/views/website/technical'),
        name: '技术声明',
        meta: {
          title: '技术声明',
          noCache: true,
          affix: true
        }
      },
      {
        path: 'culture',
        component: () => import('@/views/website/culture'),
        name: '企业文化',
        meta: {
          title: '企业文化',
          noCache: true,
          affix: true
        }
      }
    ]
  },
  {
    path: '/goods',
    component: GoodsLayout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'list',
      component: () => import('@/views/goods/list'),
      name: '商品列表'
    },
    {
      path: 'detail',
      component: () => import('@/views/goods/detail'),
      name: '商品详情'
    }]
  },
  {
    path: '/evaluation/person',
    component: () => import('@/views/profile/report/evaluation/person'),
    name: '个人评测报告'
  },
  {
    path: '/evaluation/company',
    component: () => import('@/views/profile/report/evaluation/company'),
    name: '公司评测报告'
  },
  ...profileRouter
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})
