<template>
  <div class="appContainer">
    <website-header/>
    <app-main />
    <website-footer/>
  </div>
</template>
<script>
import { AppMain, WebsiteHeader, WebsiteFooter } from './components'
export default {
  name: 'Layout',
  components: {
    AppMain, WebsiteFooter, WebsiteHeader
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";
</style>

