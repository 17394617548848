<template>
  <div class="brand">
    <div class="appInner">
      <a href="/">
        <img class="logo" src="../../../assets/images/common/logo03.png" alt="">
      </a>
      <!--    banner-->
      <div class="banner">
        <el-carousel arrow="never" height="150px">
<!--          <el-carousel-item>-->
<!--            <img style="width: 979px;height: 150px" class="banner-hospital" src="../../../assets/images/brand/brand02.png" alt="">-->
<!--          </el-carousel-item>-->
<!--          <el-carousel-item>-->
<!--            <img style="width: 979px;height: 150px" class="banner-hospital" src="../../../assets/images/brand/brand03.png" alt="">-->
<!--          </el-carousel-item>-->
          <el-carousel-item>
            <img style="width: 979px;height: 150px" class="banner-hospital" src="../../../assets/images/brand/brand09.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img style="width: 979px;height: 150px" class="banner-hospital" src="../../../assets/images/brand/brand10.png" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdBrand',
  data() {
    return {
      params: {
        keyword: ''
      }
    }
  },
  methods: {
    search() {

    }
  }
}
</script>

<style lang="scss" scoped>

  .el-carousel {
    padding: 5px 0;
    width: 979px;
    /*height: 150px;*/
    img{
      /*width: 500px;*/
      /*height: 120px;*/
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #d3dce6;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #99a9bf;
    }
  }

  .appInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      width:150px;
      /*background-color: #36a1ad*/
    }
  }
</style>

